import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import { DiscussionEmbed } from 'disqus-react';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';

import Sticky from 'react-stickynode';
import Styles from "./blog-post.module.css"


const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const { edges } = props.data.allMarkdownRemark;

  return (
    <Layout3>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
        <div>
        <div className={Styles.bar}>
          <div style={{display:"flex"}}>
            <div className={Styles.headerleft}>
              {/* <Cityset1 /> */}
              <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}><img style={{width:"100%",margin:"2px"}} src={"/sdgsicon/sdg_"+post.frontmatter.sdgs_no+".png"}/></a>
            </div>
            <div className={Styles.headerno}>
              {post.frontmatter.no}
            </div>
            <div className={Styles.headertitle}>
              {post.frontmatter.title}
            </div>
            <div className={Styles.headertitle2}>
              {post.frontmatter.height_name+"×"+post.frontmatter.color_name}
            </div>
            <div className={Styles.headerright}>
              <a className={Styles.button} href="/top">HOME</a>
            </div>
          </div>
        </div>
        </div>
      </Sticky>

      <div>
        <Link to={"/"+post.frontmatter.no+""} className={Styles.dis2}>
          <h2>国土像を俯瞰する（全国の傾向を見る）</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/tenkei"} className={Styles.dis2}>
          <h2>典型的な都市とその解説を見る</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/erabu"} className={Styles.dis2}>
          <h2>自分で地域を選んで探究する</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/hikaku"} className={Styles.dis2}>
          <h2>2地域を比較して探究する</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/0"} className={Styles.dis2}>
          <h2>関連するデータを調べる</h2>
        </Link>
      </div>






<div className={Styles.cesium2dis}>
  <div style={{display:"flex"}}>
    <div style={{margin:"0% 5% 0% 5%"}}>
        {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
              <div key={node.fields.slug}>
                <PostCard
                  title={node.frontmatter.title || node.fields.slug}
                  url={node.fields.slug}
                  tags={node.frontmatter.tags}
                  sdgs_no={node.frontmatter.sdgs_no}
                  height_name={node.frontmatter.height_name}
                  color_name={node.frontmatter.color_name}
                />
              </div>
            ))}
          </div>
        )}
    </div>
  </div>

  <div>
    {post.frontmatter.tags == null ? null : (
      <div>
        {post.frontmatter.tags.map((tag: string, index: number) => (
          <Link
          key={index}
          to={`/tags/${_.kebabCase(tag)}/`}>
            <h2>
              {`#${tag}`}
            </h2>
          </Link>
        ))}
      </div>
    )}
  </div>










</div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/syousai"} className={Styles.dis2}>
          <h2>データの詳細情報を確認する</h2>
        </Link>
      </div>

    </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlugKanren($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

